import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as ClockRefresh } from "@/assets/icons/clock-refresh.svg";
import { ReactComponent as ArrowRight } from "@/assets/icons/arrow-right.svg";
import { ReactComponent as User } from "@/assets/icons/user-03.svg";
import { Link } from "react-router-dom";

export default function EmptyContent({
  isTokenEnabled,
  isReservesOver,
}: {
  isTokenEnabled: boolean;
  isReservesOver: boolean;
}) {
  return (
    <>
      <div className={styles.card}>
        <p className={styles.cardDescription}>
          {isReservesOver ? (
            <>
              Установленные резервы закончились. <br /> Обновите их после
              пополнения счетов.
            </>
          ) : isTokenEnabled ? (
            "Нет активных заявок"
          ) : (
            <>
              Вы не будете получать <br />
              новые заявки на выплаты
            </>
          )}
        </p>
      </div>
      <div className={styles.links}>
        {!isTokenEnabled && (
          <Link to={"/profile"} className={styles.link}>
            <User className={styles.linkLeftIcon} />
            <span className={styles.linkText}>Управление аккаунтом</span>
            <ArrowRight className={styles.linkRightIcon} />
          </Link>
        )}
        <Link to={"/orders"} className={styles.link}>
          <ClockRefresh className={styles.linkLeftIcon} />
          <span className={styles.linkText}>История выплат</span>
          <ArrowRight className={styles.linkRightIcon} />
        </Link>
      </div>
    </>
  );
}
