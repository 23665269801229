import Text from "@components/typography/Text/Text";
import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <>
      <h1>404 - Страница не найдена</h1>
      <Link to="/">
        <Text type="accent"> Вернуться на главную</Text>
      </Link>
    </>
  );
}
