import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQueryWithTokenWrapper from "@store/base-query";
import { IActiveOrderResponse, IOrdersResponse } from "./orders.types";
import { PER_PAGE } from "@utils/constants/shared";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: baseQueryWithTokenWrapper,
  tagTypes: ["order"],
  endpoints: (builder) => ({
    getActiveOrder: builder.query<IActiveOrderResponse, void>({
      query: () => "massmo/v1/payout_orders/active",
      providesTags: ["order"],
    }),
    getOrders: builder.query<
      IOrdersResponse,
      {
        page?: string | number;
        sort?: string;
        state?: string;
        amount?: string;
        amountFrom?: string;
        amountTo?: string;
        dateTo?: string;
        dateFrom?: string;
      }
    >({
      query: (params) => {
        let query = `massmo/v1/payout_orders?per=${PER_PAGE}`;

        if (params.page) {
          query = query + `&page=${params.page}`;
        }
        if (params.state) {
          query = query + `&q[message_type_eq]=${params.state}`;
        }
        if (params.amount) {
          query = query + `&q[amount_eq]=${params.amount}`;
        }
        if (params.amountFrom) {
          query = query + `&q[amount_gteq]=${params.amountFrom}`;
        }
        if (params.amountTo) {
          query = query + `&q[amount_lteq]=${params.amountTo}`;
        }
        if (params.dateFrom) {
          query =
            query +
            `&q[created_at_gt]=${new Date(+params.dateFrom).toISOString()}`;
        }
        if (params.dateTo) {
          query =
            query +
            `&q[created_at_lt]=${new Date(+params.dateTo).toISOString()}`;
        }
        return query;
      },
      providesTags: ["order"],
    }),
    completeOrder: builder.mutation<
      IActiveOrderResponse,
      { id: string | number; files: any[] }
    >({
      query: (params) => {
        var formdata = new FormData();
        formdata.append("proof", params.files[0]);

        return {
          url: `massmo/v1/payout_orders/${params.id}/complete`,
          method: "POST",
          body: formdata,
        };
      },
    }),
    cancelOrder: builder.mutation<
      IActiveOrderResponse,
      { id: string | number }
    >({
      query: (params) => {
        return {
          url: `massmo/v1/payout_orders/${params.id}/cancel`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetActiveOrderQuery,
  useCompleteOrderMutation,
  useCancelOrderMutation,
  useGetOrdersQuery,
} = ordersApi;
