import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@/utils/styles/sharedStyles.module.scss";

import Title from "@components/typography/Title/Title";
import OrderItem from "./OrderItem/OrderItem";
import DropDownBlock from "@components/shared/DropDownBlock/DropDownBlock";
import OrdersPageFilters from "./OrdersPageFilters/OrdersPageFilters";
import FiltersBaseTags from "@components/filters/FiltersBaseTags/FiltersBaseTags";
import { ordersApi } from "@store/orders/orders.api";
import { IOrderItem } from "@store/orders/orders.types";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import Loader from "@components/shared/Loader/Loader";
import { PER_PAGE } from "@utils/constants/shared";

const orders = ["A", "B", "C", "D", "E"];

export default function OrdersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams);

  const [openedIndex, setOpenedIndex] = useState<number>();

  const [list, setList] = useState<IOrderItem[]>([]);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);

  const [getList, { data, isLoading, isError }] =
    ordersApi.endpoints.getOrders.useLazyQuery();
  async function getListData(page: number) {
    page === 1 && setIsRefetching(true);

    const resp: any = await getList({
      page: page,
      sort: searchParamsObject?.sort,
      state: searchParamsObject?.state,
      amount: searchParamsObject?.amount,
      amountFrom: searchParamsObject?.amountFrom,
      amountTo: searchParamsObject?.amountTo,
      dateFrom: searchParamsObject?.dateFrom,
      dateTo: searchParamsObject?.dateTo,
    });
    let curData = resp.data?.data;
    if (page === 1) {
      curData && setList(curData);
      setIsRefetching(false);
    } else {
      curData && setList((current) => current.concat(curData));
    }
  }

  useEffect(() => {
    getListData(1);
  }, [
    searchParamsObject?.sort,
    searchParamsObject?.state,
    searchParamsObject?.amount,
    searchParamsObject?.amountFrom,
    searchParamsObject?.amountTo,
    searchParamsObject?.dateFrom,
    searchParamsObject?.dateTo,
  ]);

  const { ref, inView } = useInView({ threshold: 0 });
  useEffect(() => {
    data?.meta?.next_page && inView && getListData(data?.meta?.next_page);
  }, [inView]);

  return (
    <div className={styles.page}>
      <Title>История выплат</Title>

      <DropDownBlock title="Расширенный поиск">
        <OrdersPageFilters />
      </DropDownBlock>

      <FiltersBaseTags>
        <></>
      </FiltersBaseTags>

      <div className={styles.list}>
        {list.map((item, index) => (
          <OrderItem
            data={item}
            key={item.id}
            isOpen={index === openedIndex}
            onOpenClick={() => setOpenedIndex(index)}
          />
        ))}
        {data?.data &&
          data?.data?.length >= PER_PAGE &&
          !isLoading &&
          !isError && (
            <div className={sharedStyles.observer} ref={ref}>
              <Loader />
            </div>
          )}
      </div>
    </div>
  );
}
