import React, { useRef, useState } from "react";

import styles from "./styles.module.scss";
import itemStyles from "./UploaderItem/styles.module.scss";

import { ReactComponent as FileIcon } from "@assets/icons/file.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/plus.svg";
import { FileDrop } from "react-file-drop";
import classNames from "classnames";
import UploaderItem from "./UploaderItem/UploaderItem";

interface IUploader {
  files: any[];
  setFiles: React.Dispatch<React.SetStateAction<any[]>>;
}
const maxFilesCount = 1;
export default function Uploader(props: IUploader) {
  const { files, setFiles } = props;

  const [uploadActive, setUploadActive] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  function onFileDrop(fileList: FileList | null) {
    setError(null);
    if (!fileList) {
      setUploadActive(false);
      return;
    }
    let fileListArr = Array.from(fileList);
    if (
      files.length > 1 ||
      files.length + fileListArr?.length > maxFilesCount
    ) {
      setError(`Максимальное количество файлов - ${maxFilesCount}`);
      setUploadActive(false);
      return;
    }
    setFiles((cur) => cur.concat(fileListArr));
    setUploadActive(false);
  }

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const fileListArr = e.target?.files && Array.from(e.target.files);
    if (!fileListArr) {
      setUploadActive(false);
      return;
    }
    if (
      files.length > 1 ||
      files.length + fileListArr?.length > maxFilesCount
    ) {
      setError(`Максимальное количество файлов - ${maxFilesCount}`);
      return;
    }
    setFiles((cur) => cur.concat(fileListArr));
  };

  function removeFile(fileIndex: number) {
    setFiles((files) => files.filter((file, index) => index !== fileIndex));
  }

  return (
    <>
      <FileDrop
        onDragOver={() => setUploadActive(true)}
        onDragLeave={() => setUploadActive(false)}
        onDrop={onFileDrop}
        className={styles.uploaderContainer}
      >
        <div
          className={classNames(styles.uploader, {
            [styles.active]: uploadActive,
            [styles.muted]: files.length > 0,
          })}
        >
          {files.length === 0 ? (
            <span className={styles.uploaderText}>
              <FileIcon width={24} height={24} />
              Загрузите вашу справку
            </span>
          ) : (
            <>
              {files.map((file, index) => (
                <UploaderItem
                  file={file}
                  key={index}
                  removeFile={() => removeFile(index)}
                />
              ))}
            </>
          )}
          {files.length < maxFilesCount && (
            <input
              type={"file"}
              // accept=".pdf"
              ref={fileInputRef}
              className={classNames(styles.inputFile, {
                // [styles.plus]: files.length > 0,
              })}
              onChange={handleFileSelected}
            />
          )}
        </div>
      </FileDrop>

      {error && <p className={styles.error}>{error}</p>}
    </>
  );
}
