import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@/utils/styles/sharedStyles.module.scss";

import { IWrapComponent, StyleType } from "@/utils/types/shared";
import classNames from "classnames";

interface IText extends IWrapComponent {
  type?: StyleType | "placeHolder";
}

export default function Text(props: IText) {
  const { children, type } = props;
  return (
    <>
      <span
        className={classNames(styles.value, {
          [sharedStyles.accent]: type === "accent",
          [sharedStyles.primary]: type === "primary",
          [sharedStyles.success]: type === "success",
          [sharedStyles.danger]: type === "danger",
          [sharedStyles.warning]: type === "warning",
          [sharedStyles.muted]: type === "muted",
          [sharedStyles.placeHolder]: type === "placeHolder",
        })}
      >
        {children}
      </span>
    </>
  );
}
