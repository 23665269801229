import { IWrapComponent } from "@/utils/types/shared";
import React from "react";

import styles from "./styles.module.scss";

interface ITitle extends IWrapComponent {
  rightElement?: React.ReactElement;
}

export default function Title(props: ITitle) {
  const { children, rightElement } = props;
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{children}</h1>
      {rightElement && (
        <div className={styles.rightElement}>{rightElement}</div>
      )}
    </div>
  );
}
