import React, { useState } from "react";

import styles from "./styles.module.scss";

import { useGetAuthTokenMutation } from "@/store/auth/auth.api";
import { setAccessToken } from "@/services/cookies/cookies";
import { useAppDispatch } from "@/store/hooks";
import { loggedIn } from "@/store/auth/auth.slice";
import { ReactComponent as Logo } from "@/assets/images/logo.svg";
import { ReactComponent as MailIcon } from "@/assets/icons/@.svg";
import Button from "@/components/controls/Button/Button";
import Text from "@/components/typography/Text/Text";
import InputWithLabel from "@components/controls/InputWithLabel/InputWithLabel";
import { useForm } from "react-hook-form";
import useClearCache from "@/utils/hooks/useClearCache";
import { useNavigate } from "react-router-dom";

export default function AuthPage() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onBlur" });
  const [auth, { error, isLoading, isSuccess }] = useGetAuthTokenMutation();
  const dispatch = useAppDispatch();
  const clearCache = useClearCache();

  async function onSubmit() {
    if (isLoading) {
      return;
    }
    const authData = (await auth({ secret: watch("secret") })) as any;
    if (!authData?.data) {
      return;
    }
    clearCache();
    setAccessToken(authData?.data?.access_token, authData?.data?.exp);
    dispatch(loggedIn());
    navigate("/");
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Авторизация</h1>
          <p className={styles.description}>Введите токен для входа</p>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <InputWithLabel
              leftElement={<MailIcon width={20} height={20} />}
              inputAttributes={{
                type: "password",
                ...register("secret", { required: "Поле не заполнено" }),
              }}
              type={errors?.secret ? "error" : undefined}
            />
            {errors.secret && (
              <Text type="danger">
                <>{errors?.secret.message || "Ошибка заполнения поля"}</>
              </Text>
            )}
            {error && <Text type="danger">{JSON.stringify(error)}</Text>}
            <div className={styles.submit}>
              <Button loading={false} disabled={!isValid}>
                Авторизоваться
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
