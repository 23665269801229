import React from "react";

import styles from "./styles.module.scss";

import { IOrderItem } from "@store/orders/orders.types";
import Avatar from "@components/shared/Avatar/Avatar";
import { ReactComponent as CheckIcon } from "@assets/icons/check-circle.svg";
import { ReactComponent as XIcon } from "@assets/icons/x-circle.svg";
import Text from "@components/typography/Text/Text";
import { transformCurrencyValue } from "@utils/helpers/transformCurrencyValue";
import { formatCardNumber } from "@utils/helpers/formatCardNumber";
import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import Copy from "@components/shared/Copy/Copy";
import { useGetPayoutLimitsQuery } from "@store/payoutLimits/payoutLimits.api";
import { ReactComponent as WalletIcon } from "@assets/icons/wallet-02.svg";

export interface IModalSuccess extends IModalBase {
  data?: IOrderItem;
  state: string;
}

export default function ModalSuccess({
  data,
  state = "success",
  ...rest
}: IModalSuccess) {
  const { data: limitsData } = useGetPayoutLimitsQuery();
  let limit: any =
    limitsData?.data &&
    limitsData?.data?.find((i) => i.bank_name === data?.bank_name);
  const type = state === "success" ? "success" : "danger";
  const title = state === "success" ? "Заявка оплачена" : "Заявка отменена";
  const subTitle =
    state === "success"
      ? "Чек об операции загружен в систему"
      : "Вы отменили данную заявку";
  return (
    <ModalBase {...rest}>
      <div className={styles.container}>
        <section className={styles.head}>
          <Avatar type={type as "success"} size="xl">
            {state === "success" ? <CheckIcon /> : <XIcon />}
          </Avatar>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subTitle}>{subTitle}</p>
        </section>
        <section className={styles.body}>
          <div className={styles.requisites}>
            <Text type={type}>
              {data?.amount && transformCurrencyValue(+data.amount)}{" "}
              {data?.currency?.toUpperCase()}
            </Text>
            <p>{data?.requisites && formatCardNumber(data.requisites)}</p>
          </div>
          <div className={styles.id}>
            <p>id:{data?.uuid}</p>
            <div className={styles.copy}>
              <Copy value={data?.uuid || ""} />
            </div>
          </div>
        </section>
        <section className={styles.footer}>
          <div className={styles.reserve}></div>
          {state === "success" ? (
            <div className={styles.reserve}>
              <WalletIcon />
              Резерв: {transformCurrencyValue(limit?.limit)}{" "}
              {data?.currency?.toUpperCase()}
            </div>
          ) : (
            <p className={styles.footerCancel}>
              <Text type="danger">Заявка передана другому исполнителю</Text>
            </p>
          )}
        </section>
      </div>
    </ModalBase>
  );
}
