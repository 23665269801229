export function transformCurrencyValue(number: number) {
  // const shortNumber = number.toFixed(2);
  // const [leftVal, rightVal] = shortNumber.split(".");
  // const reversedValArray = leftVal.split("").reverse();
  // let validLeftVal = reversedValArray
  //   .map((i, idx) =>
  //     (idx + 1) % 3 === 0 &&
  //     (idx !== reversedValArray.length ||
  //       (reversedValArray.includes("-") && idx !== reversedValArray.length - 1))
  //       ? ` ${i}`
  //       : i
  //   )
  //   .reverse()
  //   .join("");
  // if (validLeftVal.split("")[0] === " ") {
  //   validLeftVal = validLeftVal.replace(" ", "");
  // }
  // return `${validLeftVal}${+rightVal > 0 ? `,${+rightVal}` : ""}`;
  const formatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  });
  return formatter.format(number);
}
