import React, { useEffect } from "react";

import "./App.scss";

import AuthPage from "@pages/AuthPage/AuthPage";
import Layout from "@components/layout/Layout";
import useLogOut from "@utils/hooks/useLogOut";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "@pages/NotFoundPage/NotFoundPage";
import OrderPage from "@pages/OrderPage/OrderPage";
import ProfilePage from "@pages/ProfilePage/ProfilePage";
import { createTheme, ThemeProvider } from "@mui/material";
import OrdersPage from "@pages/OrdersPage/OrdersPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0052ff",
    },
  },
});
function App() {
  // const logOut = useLogOut();
  // useEffect(() => {
  //   logOut();
  // }, []);
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<OrderPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
