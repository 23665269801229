import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQueryWithTokenWrapper from "@store/base-query";
import { IGetExecutorResponse } from "./executor.types";
import { ordersApi } from "@store/orders/orders.api";

export const executorApi = createApi({
  reducerPath: "executorApi",
  baseQuery: baseQueryWithTokenWrapper,
  tagTypes: ["executor"],
  endpoints: (builder) => ({
    getExecutor: builder.query<IGetExecutorResponse, void>({
      query: () => "massmo/v1/executor",
      providesTags: ["executor"],
    }),
    changeExecutorState: builder.mutation<
      any,
      {
        state: string;
      }
    >({
      query: (params) => ({
        url: `massmo/v1/executor/change_state`,
        method: "POST",
        body: {
          state: params.state,
        },
      }),
      invalidatesTags: ["executor"],
    }),
  }),
});

export const { useGetExecutorQuery, useChangeExecutorStateMutation } =
  executorApi;
