import React from "react";

import styles from "./styles.module.scss";

import { Navigate, Outlet } from "react-router-dom";
import { selectIsAuthActive } from "@store/auth/auth.slice";
import { useAppSelector } from "@store/hooks";
import MobilePanel from "./MobilePanel/MobilePanel";
import Header from "./Header/Header";

export default function Layout() {
  const isAuthActive = useAppSelector(selectIsAuthActive);
  if (!isAuthActive) {
    return <Navigate to={"/auth"} />;
  }
  return (
    <>
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <Header />
          <div className={styles.content}>
            <div className={styles.bodyContainer}>
              <Outlet />
            </div>
          </div>
          <MobilePanel />
        </div>
      </main>
    </>
  );
}
