import React from "react";
import useClearCache from "./useClearCache";
import { clearAccessToken } from "@/services/cookies/cookies";
import { useAppDispatch } from "@store/hooks";
import { loggedOut } from "@store/auth/auth.slice";

export default function useLogOut() {
  const clearCache = useClearCache();
  const dispatch = useAppDispatch();

  async function logOut() {
    clearAccessToken();
    await clearCache();
    dispatch(loggedOut());
  }
  return logOut;
}
