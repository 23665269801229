import React, { useState } from "react";

import styles from "./styles.module.scss";

import { IWrapComponent } from "@/utils/types/shared";
import classNames from "classnames";
import { ReactComponent as Chevron } from "@/assets/icons/chevron-down.svg";
import { AnimatePresence, motion } from "framer-motion";

interface IDropDownBlock extends IWrapComponent {
  title: string;
}

export default function DropDownBlock(props: IDropDownBlock) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.container}>
      <button
        className={styles.title}
        onClick={() => setIsActive((cur) => !cur)}
      >
        <span>{props.title}</span>
        <motion.div
          animate={{
            rotate: isActive ? 180 : 0,
          }}
        >
          <Chevron
            className={classNames(styles.chevron, {
              [styles.active]: isActive,
            })}
          />
        </motion.div>
      </button>
      <AnimatePresence>
        {isActive && (
          <motion.div
            className={styles.body}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className={styles.bodyContainer}>{props.children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
