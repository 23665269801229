import { ReactComponent as VisaIcon } from "@/assets/images/payments-colorful/visa.svg";
import { ReactComponent as MirIcon } from "@/assets/images/payments-colorful/mir.svg";
import { ReactComponent as MastercardIcon } from "@/assets/images/payments-colorful/mastercard.svg";
import { ReactComponent as MaestroIcon } from "@/assets/images/payments-colorful/maestro.svg";
import { ReactComponent as BelcardIcon } from "@/assets/images/payments-colorful/belcart.svg";
import { ReactComponent as HumocardIcon } from "@/assets/images/payments-colorful/humocard.svg";

export const paymentColorFullIcons = {
  visa: <VisaIcon />,
  mir: <MirIcon />,
  mastercard: <MastercardIcon />,
  maestro: <MaestroIcon />,
  belcard: <BelcardIcon />,
  humo: <HumocardIcon />,
};
