import React, { useRef, useState, useEffect } from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@/utils/styles/sharedStyles.module.scss";

import classNames from "classnames";
import useOnClickOutside from "@/utils/hooks/useClickOutside";
import { ReactComponent as Chevron } from "@/assets/icons/chevron-down.svg";
import { SizeType } from "@/utils/types/shared";

export interface IDropDown {
  title?: string;
  items: string[];
  placeholder?: string;
  onChange?: (value: string, index?: number) => void;
  onActiveChange?: (value: boolean) => void;
  selected?: string;
  size?: SizeType;
  mode?: "base";
  icon?: React.ReactElement;
  selectedClassName?: string;
  white?: boolean;
}

export default function DropDown(props: IDropDown) {
  const {
    title,
    items,
    placeholder,
    onChange,
    selected,
    onActiveChange,
    size,
    mode,
    icon,
    selectedClassName,
    white,
  } = props;

  const [isDdVisible, setIsDdVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  const ddRef = useRef<HTMLUListElement>(null);
  const togglerRef = useRef<HTMLButtonElement>(null);

  function hideDd() {
    setIsDdVisible(false);
  }

  useOnClickOutside(ddRef, hideDd, togglerRef);

  function toggleVisible() {
    setIsDdVisible((curVal) => !curVal);
  }
  function selectItem(item: string, index?: number) {
    if (item == selectedItem) {
      return;
    }
    setSelectedItem(item);
    onChange && onChange(item, index);
    hideDd();
  }

  useEffect(() => {
    if (selected) {
      setSelectedItem(selected);
    } else {
      setSelectedItem(placeholder ? placeholder : items[0]);
    }
  }, [selected]);

  useEffect(() => {
    onActiveChange && onActiveChange(isDdVisible);
  }, [isDdVisible]);
  return (
    <div
      className={classNames(sharedStyles.paper, styles.wrapper, {
        [styles.withoutTitle]: !title,
        [styles.md]: size === "md",
        [styles.sm]: size === "sm",
        [styles.base]: mode === "base",
        [styles.white]: Boolean(white),
      })}
    >
      {title && <div className={styles.title}>{title}</div>}
      <div className={classNames(styles.container)}>
        <button
          ref={togglerRef}
          className={classNames(styles.button)}
          onClick={toggleVisible}
        >
          <span
            className={classNames(styles.selectedItem, selectedClassName, {
              [styles.placeholderSelected]: placeholder === selectedItem,
              [styles.baseSelected]: mode === "base",
            })}
          >
            {icon && <span className={styles.icon}>{icon}</span>}{" "}
            <span className={styles.selectedText}>
              {selected || selectedItem}
            </span>
          </span>
          <Chevron
            className={classNames(styles.chevron, {
              [styles.active]: isDdVisible,
            })}
          />
        </button>
        <ul
          ref={ddRef}
          className={classNames(styles.dropDown, {
            [styles.visible]: isDdVisible,
          })}
        >
          {/* {placeholder && (
            <li
              key={placeholder}
              className={classNames(styles.item, {
                [styles.active]: placeholder === selectedItem,
              })}
              onClick={() => selectItem(placeholder)}
            >
              {placeholder}
            </li>
          )} */}
          {items
            .filter((i) => i !== placeholder)
            .map((item, index) => (
              <li
                key={item + String(index)}
                className={classNames(styles.item, {
                  [styles.active]: item === selectedItem,
                })}
                onClick={() => selectItem(item, index)}
              >
                {item}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
