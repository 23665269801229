import React, { useState } from "react";

import styles from "./styles.module.scss";

import Text from "@components/typography/Text/Text";
import classNames from "classnames";
import Copy from "@components/shared/Copy/Copy";
import Uploader from "@components/controls/Uploader/Uploader";
import ModalCancel from "@components/modals/ModalCancel/ModalCancel";
import { IOrderItem } from "@store/orders/orders.types";
import {
  useGetBanksListQuery,
  useGetPayoutLimitsQuery,
} from "@store/payoutLimits/payoutLimits.api";
import { formatCardNumber } from "@utils/helpers/formatCardNumber";
import { motion } from "framer-motion";
import { ReactComponent as CancelIcon } from "@/assets/icons/x-close.svg";
import { motionButtonProps } from "@utils/styles/motionSettings";
import { transformCurrencyValue } from "@utils/helpers/transformCurrencyValue";
import { useSearchParams } from "react-router-dom";
import { useCompleteOrderMutation } from "@store/orders/orders.api";
import { paymentColorFullIcons } from "@utils/constants/paymentColorFulIcons";
import { CircularProgress } from "@mui/material";

export default function OrderContent({ data }: { data: IOrderItem }) {
  const { refetch: refetchLimits } = useGetPayoutLimitsQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [files, setFiles] = useState<any[]>([]);

  const [completeOrder, { isLoading: isCompleteLoading }] =
    useCompleteOrderMutation();

  async function onSubmit() {
    const resp: any = await completeOrder({
      files,
      id: data.id,
    });
    if (resp?.data?.status === "ok") {
      searchParams.set("success_modal", "success");
      setSearchParams(searchParams);
      refetchLimits();
    }
  }

  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery(
    {}
  );
  let bank;
  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === data.bank_name);
  }

  return (
    <>
      <ModalCancel
        isOpen={Boolean(searchParams.get("cancel_modal"))}
        onClose={() => {
          searchParams.delete("cancel_modal");
          setSearchParams(searchParams);
        }}
      />
      <section className={styles.amount}>
        <div className={styles.amountValue}>
          {transformCurrencyValue(+data.amount)} {data.currency?.toUpperCase()}
        </div>
        <div className={styles.amountText}>
          Переведите{" "}
          <strong>
            <Text type="accent">одним платежом</Text>
          </strong>{" "}
          по указанным реквизитам
        </div>
      </section>
      <section className={classNames(styles.requisite, styles.paper)}>
        <div className={styles.bank}>
          <div className={styles.bankLogo}>
            <img src={bank?.logo} alt="logo" />
          </div>
          <div className={styles.bankInfo}>
            <div className={styles.bankName}>Банк {bank?.name}</div>
          </div>
        </div>
        <div className={styles.cardNumber}>
          <div className={styles.payment}>
            {paymentColorFullIcons["visa"] || ""}
          </div>{" "}
          {formatCardNumber(data.requisites)}
          <div className={styles.copy}>
            <Copy value={data.requisites} />
          </div>
        </div>
        <div className={styles.uuid}>
          <p>ID: {data.uuid}</p>
          <div className={styles.copy}>
            <Copy value={data.uuid} />
          </div>
        </div>
      </section>
      <motion.button
        onClick={() => {
          searchParams.set("cancel_modal", "1");
          setSearchParams(searchParams);
        }}
        {...motionButtonProps}
        className={classNames(styles.paper, styles.cancel)}
      >
        <CancelIcon /> Отменить заявку на выплату
      </motion.button>
      <div className={styles.fileDescription}>
        Для подтверждения оплаты вам необходимо{" "}
        <Text type="accent">
          {" "}
          поделиться справкой о переводе из банковского приложения
        </Text>{" "}
        или прикрепить ее ниже:
      </div>
      <Uploader files={files} setFiles={setFiles} />
      {files.length > 0 && (
        <motion.button
          onClick={onSubmit}
          {...motionButtonProps}
          className={styles.submit}
          disabled={isCompleteLoading}
        >
          {isCompleteLoading ? (
            <CircularProgress size={20} />
          ) : (
            "Подтвердить оплату"
          )}
        </motion.button>
      )}
    </>
  );
}
