import React from "react";

import styles from "./styles.module.scss";

import classNames from "classnames";
import Title from "@components/typography/Title/Title";
import Tooltip from "@mui/material/Tooltip";
import Reserve from "./Reserve/Reserve";
import useLogOut from "@utils/hooks/useLogOut";
import TokenBlock from "./TokenBlock/TokenBlock";
import { ReactComponent as LogOutIcon } from "@/assets/icons/log-out-01.svg";
import { motion } from "framer-motion";
import { motionButtonProps } from "@utils/styles/motionSettings";
import { useGetUserInfoQuery } from "@store/user/user.api";
import { Skeleton } from "@mui/material";
import { useGetPayoutLimitsQuery } from "@store/payoutLimits/payoutLimits.api";

export default function ProfilePage() {
  const { data: userData, isLoading: isUserLoading } = useGetUserInfoQuery();
  const { data } = useGetPayoutLimitsQuery();

  const logOut = useLogOut();

  return (
    <>
      <Title>Управление аккаунтом</Title>
      <section className={styles.section}>
        <div className={classNames(styles.paper, styles.userBlock)}>
          <p className={styles.userName}>
            {isUserLoading ? (
              <Skeleton width={150} height={30} />
            ) : (
              userData?.data.name
            )}
          </p>
          <Tooltip title="Выход">
            <motion.button
              onClick={logOut}
              className={styles.logOut}
              {...motionButtonProps}
            >
              <LogOutIcon />
            </motion.button>
          </Tooltip>
        </div>
        <TokenBlock />
      </section>
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>Резервы</h2>
        {data?.data.map((i) => (
          <Reserve data={i} key={i.id} />
        ))}
      </section>
    </>
  );
}
