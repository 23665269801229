import React from "react";

import styles from "./styles.module.scss";

import { ReactComponent as ClockRefresh } from "@/assets/icons/clock-refresh.svg";
import { ReactComponent as Card } from "@/assets/icons/credit-card-upload.svg";
import { ReactComponent as User } from "@/assets/icons/user-03.svg";
import classNames from "classnames";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { motionButtonProps } from "@utils/styles/motionSettings";
import { useGetActiveOrderQuery } from "@store/orders/orders.api";

export default function MobilePanel() {
  const {} = useGetActiveOrderQuery();

  return (
    <div className={styles.container}>
      <motion.div {...motionButtonProps} className={styles.linkWrapper}>
        <NavLink
          className={({ isActive }) => classNames(isActive && styles.active)}
          to={"/orders"}
        >
          <ClockRefresh />
        </NavLink>
      </motion.div>
      <motion.div {...motionButtonProps} className={styles.linkWrapper}>
        <NavLink
          className={({ isActive }) => classNames(isActive && styles.active)}
          to={"/"}
        >
          <Card />
        </NavLink>
      </motion.div>

      <motion.div {...motionButtonProps} className={styles.linkWrapper}>
        <NavLink
          className={({ isActive }) => classNames(isActive && styles.active)}
          to={"/profile"}
        >
          <User />
        </NavLink>
      </motion.div>
    </div>
  );
}
