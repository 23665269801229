const monthLabel = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export function changeTime(timeData: number) {
  const time = timeData.toString();
  return time.length === 1 ? "0" + time : time;
}

function parseTime(date: any, withSeconds: boolean = false) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${changeTime(hours)}:${changeTime(minutes)}${
    withSeconds ? `:${changeTime(seconds)}` : ""
  }`;
}
type parseMode = "default" | "timeOnly" | "numeric";

function getDateWithoutHMS(val: Date) {
  const date = new Date(val);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0, 0);
  return date.getTime();
}

export function parseDate(
  dateData: string | Date | number,
  mode: parseMode = "default",
  withSeconds: boolean = false
) {
  const now = new Date();
  const date = new Date(dateData.toString().replace(/-/g, "/"));

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  if (mode === "timeOnly") {
    return parseTime(date, withSeconds);
  }
  if (mode === "default" && getDateWithoutHMS(date) == getDateWithoutHMS(now)) {
    return `Сегодня в ${parseTime(date, withSeconds)}`;
  }
  if (
    mode === "default" &&
    getDateWithoutHMS(date) == getDateWithoutHMS(yesterday)
  ) {
    return `Вчера в ${parseTime(date, withSeconds)}`;
  }

  if (mode === "numeric" && getDateWithoutHMS(date) == getDateWithoutHMS(now)) {
    return `Сегодня в ${parseTime(date, withSeconds)}`;
  }
  if (
    mode === "numeric" &&
    getDateWithoutHMS(date) > getDateWithoutHMS(yesterday)
  ) {
    return `Вчера в ${parseTime(date, withSeconds)}`;
  }
  if (mode === "numeric") {
    return `${changeTime(day)}.${changeTime(month + 1)}.${year
      .toString()
      .slice(-2)}, ${parseTime(date, withSeconds)}`;
  }
  return `${day} ${monthLabel[month]} ${year} г., в ${parseTime(
    date,
    withSeconds
  )}`;
}
