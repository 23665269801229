import React from "react";

import styles from "./styles.module.scss";
import sharedStyles from "@/utils/styles/sharedStyles.module.scss";

import { IWrapComponent } from "@/utils/types/shared";
import useFiltersTags from "@/utils/hooks/useFiltersTags";
import Text from "@/components/typography/Text/Text";
import FilterTag from "@/components/filters/FilterTag/FilterTag";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useSearchParams } from "react-router-dom";
import { transformCurrencyValue } from "@utils/helpers/transformCurrencyValue";

export default function FiltersBaseTags({ children }: IWrapComponent) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams);

  const { isTagsIncludes, clearFilters } = useFiltersTags();

  if (!isTagsIncludes) {
    return <></>;
  }
  return (
    <div className={sharedStyles.filtersContainer}>
      <div className={sharedStyles.filtersResults}>
        <div className={sharedStyles.desktop}>
          <button onClick={clearFilters}>
            <Text type="accent"> Сбросить фильтры</Text>
          </button>
        </div>
        <div className={sharedStyles.mobile}>
          <div className={sharedStyles.filtersTitle}>
            <Text type="muted">Выбранные параметры поиска</Text>
            <button onClick={clearFilters}>
              <Text type="accent"> Сбросить все</Text>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {children}

        {searchParamsObject.amountFrom && (
          <FilterTag
            query={"amountFrom"}
            title="Сумма (от)"
            value={`${
              searchParamsObject.amountFrom
                ? `${transformCurrencyValue(
                    +searchParamsObject.amountFrom
                  )} ${"RUB"}`
                : ""
            }`}
          />
        )}
        {searchParamsObject.amountTo && (
          <FilterTag
            query={"amountTo"}
            title="Сумма (до)"
            value={`${
              searchParamsObject.amountTo
                ? `${transformCurrencyValue(
                    +searchParamsObject.amountTo
                  )} ${"RUB"}`
                : ""
            }`}
          />
        )}

        {searchParamsObject.dateFrom && (
          <FilterTag
            query={"dateFrom"}
            title="Дата (от)"
            value={`${
              searchParamsObject.dateFrom
                ? `${dayjs(+searchParamsObject.dateFrom)
                    .locale("ru")
                    .format("DD.MM.YYYY HH:mm")}`
                : ""
            }`}
          />
        )}
        {searchParamsObject.dateTo && (
          <FilterTag
            query={"dateTo"}
            title="Дата (до)"
            value={`${
              searchParamsObject.dateTo
                ? `${dayjs(+searchParamsObject.dateTo)
                    .locale("ru")
                    .format("DD.MM.YYYY HH:mm")}`
                : ""
            }`}
          />
        )}
      </div>
    </div>
  );
}
