import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import Title from "@components/typography/Title/Title";
import Text from "@components/typography/Text/Text";
import EmptyContent from "./EmptyContent/EmptyContent";
import OrderContent from "./OrderContent/OrderContent";
import ModalSuccess from "@components/modals/ModalSuccess/ModalSuccess";
import ReservesBlock from "./ReservesBlock/ReservesBlock";
import { useGetExecutorQuery } from "@store/executor/executor.api";
import { ordersApi, useGetActiveOrderQuery } from "@store/orders/orders.api";
import { parseDate } from "@utils/helpers/parseDate";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "@store/hooks";
import { CircularProgress } from "@mui/material";

export default function OrderPage() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: executorData, isLoading: isExecutorLoading } =
    useGetExecutorQuery();
  const { data: orderData, isLoading, error } = useGetActiveOrderQuery();
  const isTokenEnabled = executorData?.data?.state === "enabled";
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (navigator.serviceWorker) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.onmessage = function (event) {
          const file = event.data.file;
          alert(file.name + " | " + file + " | " + file.size);
          setMessage(file.name + " | " + file + " | " + file.size);
        };
      });
    }
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );
  }

  // @ts-ignore
  const isReservesOver = error?.status === 404;

  function getTitle() {
    if (isTokenEnabled) {
      if (isReservesOver) {
        return <Text type="danger">Резервы исчерпаны</Text>;
      }

      return (
        <>
          Заявка на выплату
          {orderData?.data.id ? (
            <Text type="muted"> #{orderData?.data?.id}</Text>
          ) : (
            ""
          )}
        </>
      );
    }
    return <Text type="danger">Токен отключен</Text>;
  }
  return (
    <>
      <h1 id="filedata">file:{message}</h1>
      <ReservesBlock />
      <div className={styles.container}>
        <ModalSuccess
          isOpen={Boolean(searchParams.get("success_modal"))}
          onClose={() => {
            searchParams.delete("success_modal");
            setSearchParams(searchParams);
            dispatch(ordersApi.util.resetApiState());
          }}
          state={searchParams.get("success_modal") || ""}
          data={orderData?.data}
        />
        <div className={styles.titleBlock}>
          <Title>{getTitle()}</Title>
          {orderData?.data?.created_at && (
            <div className={styles.titleDescription}>
              Создана:{" "}
              <Text type="muted">{parseDate(orderData.data.created_at)}</Text>
            </div>
          )}
        </div>
        {orderData?.data?.id ? (
          <OrderContent data={orderData.data} />
        ) : (
          <EmptyContent
            isTokenEnabled={isTokenEnabled}
            isReservesOver={isReservesOver}
          />
        )}
      </div>
    </>
  );
}
