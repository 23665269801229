import React, { useState } from "react";

import styles from "./styles.module.scss";

import ModalBase, { IModalBase } from "../ModalBase/ModalBase";
import { motion } from "framer-motion";
import { motionButtonProps } from "@utils/styles/motionSettings";
import Avatar from "@components/shared/Avatar/Avatar";
import { ReactComponent as SwitchIcon } from "@assets/icons/switch-horizontal-01.svg";
import { ReactComponent as CardIcon } from "@assets/icons/credit-card-x.svg";
import { ReactComponent as AlertIcon } from "@assets/icons/alert-hexagon.svg";
import classNames from "classnames";
import {
  useCancelOrderMutation,
  useGetActiveOrderQuery,
} from "@store/orders/orders.api";
import { useChangeExecutorStateMutation } from "@store/executor/executor.api";
import { useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function ModalCancel(props: IModalBase) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cancelReason, setCancelReason] = useState("");
  const [cancelOrder, { isLoading: cancelLoading }] = useCancelOrderMutation();
  const [changeExecutorState, { isLoading: changeLoading }] =
    useChangeExecutorStateMutation();
  const { data: orderData } = useGetActiveOrderQuery();

  function onRequestConfirmed() {
    searchParams.delete("cancel_modal");
    searchParams.set("success_modal", "cancel");
    setSearchParams(searchParams);
  }

  async function onSubmit() {
    if (!cancelReason || !orderData?.data?.id) {
      return;
    }
    if (cancelReason === "blocked") {
      await cancelOrder({ id: orderData.data.id });
      onRequestConfirmed();
      return;
    }
    await changeExecutorState({ state: "disabled" });
    onRequestConfirmed();
    return;
  }
  return (
    <ModalBase {...props}>
      <div className={styles.container}>
        <div className={styles.content}>
          <section>
            <h2 className={styles.title}>Отменить заявку</h2>
            <p className={styles.subTitle}>
              Выберите причину отмены заявки на выплату
            </p>
          </section>
          <section className={styles.variants}>
            <motion.button
              className={classNames(styles.variant, {
                [styles.active]: cancelReason === "blocked",
              })}
              onClick={() => setCancelReason("blocked")}
            >
              <Avatar type="danger">
                <SwitchIcon />
              </Avatar>
              <p>Карта получателя заблокирована</p>
            </motion.button>
            <motion.button
              className={classNames(styles.variant, {
                [styles.active]: cancelReason === "troubles",
              })}
              onClick={() => setCancelReason("troubles")}
            >
              <Avatar type="danger">
                <CardIcon />
              </Avatar>
              <p>Проблемы с отправлением платежа</p>
            </motion.button>
          </section>
          {cancelReason === "troubles" && (
            <section className={styles.notice}>
              <AlertIcon />
              <p>
                Текущая заявка на выплату перейдет к другому исполнителю, а ваш
                токен будет отключён
              </p>
            </section>
          )}
        </div>
        <motion.button
          {...motionButtonProps}
          className={classNames(styles.submit)}
          disabled={!Boolean(cancelReason) || changeLoading || cancelLoading}
          onClick={onSubmit}
        >
          {changeLoading || cancelLoading ? (
            <CircularProgress size={"20"} />
          ) : (
            "Отменить заявку"
          )}
        </motion.button>
      </div>
    </ModalBase>
  );
}
