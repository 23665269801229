import React from "react";

import styles from "./styles.module.scss";

import Avatar from "@components/shared/Avatar/Avatar";
import Copy from "@components/shared/Copy/Copy";
import { ReactComponent as CheckIcon } from "@assets/icons/check-circle.svg";
import { ReactComponent as FileIcon } from "@assets/icons/file-05.svg";
import { motion } from "framer-motion";
import { IOrderItem } from "@store/orders/orders.types";
import { formatCardNumber } from "@utils/helpers/formatCardNumber";
import { useGetBanksListQuery } from "@store/payoutLimits/payoutLimits.api";
import { transformCurrencyValue } from "@utils/helpers/transformCurrencyValue";
import { parseDate } from "@utils/helpers/parseDate";
import { paymentColorFullIcons } from "@utils/constants/paymentColorFulIcons";

interface IOrderItemProps {
  isOpen: boolean;
  onOpenClick: () => void;
  data: IOrderItem;
}

function getTypeData(state: string) {
  switch (state) {
    case "completed":
      return {
        type: "success",
        title: "Заявка оплачена",
      };
    case "canceled":
      return {
        type: "danger",
        title: "Заявка отменена",
      };
    case "pending":
      return {
        type: "accent",
        title: "Заявка создана",
      };
    default:
      return {
        type: "muted",
        title: "Ошибка: заявка не распознана",
      };
  }
}

export default function OrderItem({
  isOpen,
  onOpenClick,
  data,
}: IOrderItemProps) {
  const { data: banksData, isLoading: isBanksLoading } = useGetBanksListQuery(
    {}
  );
  let bank;
  if (banksData?.data) {
    bank = banksData.data.find((i) => i.alias === data.bank_name);
  }

  function onItemClick() {
    if (isOpen) {
      return;
    }
    onOpenClick();
  }

  const typeData = getTypeData(data.state);

  return (
    <div className={styles.item} onClick={onItemClick}>
      <div className={styles.itemTop}>
        <div className={styles.itemStatus}>
          <Avatar type={typeData.type as "accent"} mode="square">
            <CheckIcon />
          </Avatar>
          <div className={styles.textBlock}>
            <div className={styles.statusTitle}>{typeData.title}</div>
            <div className={styles.statusDescription}>
              Создана: {parseDate(data.created_at, "numeric")}
            </div>
          </div>
        </div>
        <div className={styles.amount}>
          {data.amount && transformCurrencyValue(+data.amount)}{" "}
          {data?.currency?.toUpperCase()}
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.bottom}>
          <div className={styles.requisites}>
            <div className={styles.bank}>
              <img
                className={styles.bankLogo}
                src={bank?.logo}
                alt="Bank logo"
              />
              <span>{bank?.name}</span>
            </div>
            <div className={styles.paySystem}>
              {paymentColorFullIcons[data.card_brand as "visa"] || ""}
              <p>{data.requisites && formatCardNumber(data.requisites)}</p>
            </div>
          </div>
        </div>
        {
          <motion.div
            initial={{ maxHeight: 0 }}
            animate={{ maxHeight: isOpen ? 200 : 0 }}
            className={styles.dropContent}
          >
            <div className={styles.block}>
              <div className={styles.blockTitle}>ID заявки</div>
              <div className={styles.idContainer}>
                <span>{data.uuid}</span>{" "}
                <div className={styles.copy}>
                  <Copy value={data.uuid} />
                </div>
              </div>
            </div>

            {data.proof && (
              <div className={styles.block}>
                <div className={styles.blockTitle}>Справка о платеже</div>
                <div className={styles.receipt}>
                  <div className={styles.receiptContainer}>
                    <FileIcon />
                    <span>{data.proof.filename}</span>
                  </div>{" "}
                  <a href={data.proof.url}>Открыть файл -{">"}</a>
                </div>
              </div>
            )}
          </motion.div>
        }
      </div>
    </div>
  );
}
